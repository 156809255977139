import { default as indexsy7PfYqXsTMeta } from "/codebuild/output/src677142924/src/pages/[slug]/index.vue?macro=true";
import { default as accept_45inviteJUPddyBbz7Meta } from "/codebuild/output/src677142924/src/pages/accept-invite.vue?macro=true";
import { default as extra_45purchasegb49gHXllyMeta } from "/codebuild/output/src677142924/src/pages/account/extra-purchase.vue?macro=true";
import { default as indexmy3QILt5YpMeta } from "/codebuild/output/src677142924/src/pages/account/index.vue?macro=true";
import { default as settingsq1VVIvgYpEMeta } from "/codebuild/output/src677142924/src/pages/account/settings.vue?macro=true";
import { default as subscriptionVrVETb6K5eMeta } from "/codebuild/output/src677142924/src/pages/account/subscription.vue?macro=true";
import { default as transactionsVM9PXVyjjcMeta } from "/codebuild/output/src677142924/src/pages/account/transactions.vue?macro=true";
import { default as update_45subscriptionIJQUv205GoMeta } from "/codebuild/output/src677142924/src/pages/account/update-subscription.vue?macro=true";
import { default as previewcvSxiKydKIMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/emails/[id]/preview.vue?macro=true";
import { default as composeYSVhDEBHqKMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/emails/compose.vue?macro=true";
import { default as indexWnOjsEVq2bMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/emails/index.vue?macro=true";
import { default as emailsrxNCJ6yULUMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/emails.vue?macro=true";
import { default as editNJyiAIsLKoMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/events/[id]/edit.vue?macro=true";
import { default as registrationsCdF5iyQzvGMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/events/[id]/registrations.vue?macro=true";
import { default as index3E9hlFmNUfMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/events/index.vue?macro=true";
import { default as eventsWL8h3p1gw0Meta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/events.vue?macro=true";
import { default as filesxKfb64BQuXMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/files.vue?macro=true";
import { default as gallery3SScw1kodmMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/gallery.vue?macro=true";
import { default as group_45detailsPfpaNv1TYZMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/group-details.vue?macro=true";
import { default as group_45settingsJDifhYGO4ZMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/group-settings.vue?macro=true";
import { default as indexSD4exQQm2LMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/index.vue?macro=true";
import { default as members63UFgSt62jMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/members.vue?macro=true";
import { default as editG3CQXvX862Meta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/news/[id]/edit.vue?macro=true";
import { default as index3SAwKaxCNsMeta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/news/index.vue?macro=true";
import { default as newszkB41riW74Meta } from "/codebuild/output/src677142924/src/pages/admin/groups/[slug]/news.vue?macro=true";
import { default as _91slug_93XUV6fZR7qOMeta } from "/codebuild/output/src677142924/src/pages/donate/[slug].vue?macro=true";
import { default as indexEcHBRSO7JbMeta } from "/codebuild/output/src677142924/src/pages/donate/index.vue?macro=true";
import { default as email_45unsubscribeZGNF9uWpk5Meta } from "/codebuild/output/src677142924/src/pages/email-unsubscribe.vue?macro=true";
import { default as _91slug_93BWxmvugq14Meta } from "/codebuild/output/src677142924/src/pages/events/[slug].vue?macro=true";
import { default as indexTCBQ0cIxnUMeta } from "/codebuild/output/src677142924/src/pages/events/index.vue?macro=true";
import { default as forgot_45passwordn5iZUB0GEGMeta } from "/codebuild/output/src677142924/src/pages/forgot-password.vue?macro=true";
import { default as fileswsbPWkyeTsMeta } from "/codebuild/output/src677142924/src/pages/groups/[slug]/files.vue?macro=true";
import { default as gallerycDP9TB1R9AMeta } from "/codebuild/output/src677142924/src/pages/groups/[slug]/gallery.vue?macro=true";
import { default as indexEGI6Z0dSGkMeta } from "/codebuild/output/src677142924/src/pages/groups/[slug]/index.vue?macro=true";
import { default as shop0DwIfWEvSLMeta } from "/codebuild/output/src677142924/src/pages/groups/[slug]/shop.vue?macro=true";
import { default as _91slug_93cicg3aGMo3Meta } from "/codebuild/output/src677142924/src/pages/groups/[slug].vue?macro=true";
import { default as index5UftbyO3XoMeta } from "/codebuild/output/src677142924/src/pages/groups/index.vue?macro=true";
import { default as indexJWg7IsRa1mMeta } from "/codebuild/output/src677142924/src/pages/index.vue?macro=true";
import { default as joinKDQHdtUNEdMeta } from "/codebuild/output/src677142924/src/pages/join.vue?macro=true";
import { default as loginCaRL6GixIcMeta } from "/codebuild/output/src677142924/src/pages/login.vue?macro=true";
import { default as confirmationbMcD9hzkNRMeta } from "/codebuild/output/src677142924/src/pages/membership/confirmation.vue?macro=true";
import { default as indexXYZyT4yFrtMeta } from "/codebuild/output/src677142924/src/pages/membership/index.vue?macro=true";
import { default as _91slug_93cHDmUfCRj5Meta } from "/codebuild/output/src677142924/src/pages/news/[slug].vue?macro=true";
import { default as indexxSaht5fT1wMeta } from "/codebuild/output/src677142924/src/pages/news/index.vue?macro=true";
import { default as reset_45passwordrz56Ta8mdPMeta } from "/codebuild/output/src677142924/src/pages/reset-password.vue?macro=true";
import { default as setup_452fafjFhhfFABGMeta } from "/codebuild/output/src677142924/src/pages/setup-2fa.vue?macro=true";
import { default as shopePhqseG82PMeta } from "/codebuild/output/src677142924/src/pages/shop.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/codebuild/output/src677142924/src/pages/[slug]/index.vue")
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/codebuild/output/src677142924/src/pages/accept-invite.vue")
  },
  {
    name: "account-extra-purchase",
    path: "/account/extra-purchase",
    meta: extra_45purchasegb49gHXllyMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/account/extra-purchase.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexmy3QILt5YpMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/account/index.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsq1VVIvgYpEMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/account/settings.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionVrVETb6K5eMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/account/subscription.vue")
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactionsVM9PXVyjjcMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/account/transactions.vue")
  },
  {
    name: "account-update-subscription",
    path: "/account/update-subscription",
    meta: update_45subscriptionIJQUv205GoMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/account/update-subscription.vue")
  },
  {
    name: emailsrxNCJ6yULUMeta?.name,
    path: "/admin/groups/:slug()/emails",
    meta: emailsrxNCJ6yULUMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/emails.vue"),
    children: [
  {
    name: "admin-groups-slug-emails-id-preview",
    path: ":id()/preview",
    meta: previewcvSxiKydKIMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/emails/[id]/preview.vue")
  },
  {
    name: "admin-groups-slug-emails-compose",
    path: "compose",
    meta: composeYSVhDEBHqKMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/emails/compose.vue")
  },
  {
    name: "admin-groups-slug-emails",
    path: "",
    meta: indexWnOjsEVq2bMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/emails/index.vue")
  }
]
  },
  {
    name: eventsWL8h3p1gw0Meta?.name,
    path: "/admin/groups/:slug()/events",
    meta: eventsWL8h3p1gw0Meta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/events.vue"),
    children: [
  {
    name: "admin-groups-slug-events-id-edit",
    path: ":id()/edit",
    meta: editNJyiAIsLKoMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/events/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-events-id-registrations",
    path: ":id()/registrations",
    meta: registrationsCdF5iyQzvGMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/events/[id]/registrations.vue")
  },
  {
    name: "admin-groups-slug-events",
    path: "",
    meta: index3E9hlFmNUfMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/events/index.vue")
  }
]
  },
  {
    name: "admin-groups-slug-files",
    path: "/admin/groups/:slug()/files",
    meta: filesxKfb64BQuXMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/files.vue")
  },
  {
    name: "admin-groups-slug-gallery",
    path: "/admin/groups/:slug()/gallery",
    meta: gallery3SScw1kodmMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/gallery.vue")
  },
  {
    name: "admin-groups-slug-group-details",
    path: "/admin/groups/:slug()/group-details",
    meta: group_45detailsPfpaNv1TYZMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/group-details.vue")
  },
  {
    name: "admin-groups-slug-group-settings",
    path: "/admin/groups/:slug()/group-settings",
    meta: group_45settingsJDifhYGO4ZMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/group-settings.vue")
  },
  {
    name: "admin-groups-slug",
    path: "/admin/groups/:slug()",
    meta: indexSD4exQQm2LMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/index.vue")
  },
  {
    name: "admin-groups-slug-members",
    path: "/admin/groups/:slug()/members",
    meta: members63UFgSt62jMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/members.vue")
  },
  {
    name: newszkB41riW74Meta?.name,
    path: "/admin/groups/:slug()/news",
    meta: newszkB41riW74Meta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/news.vue"),
    children: [
  {
    name: "admin-groups-slug-news-id-edit",
    path: ":id()/edit",
    meta: editG3CQXvX862Meta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/news/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-news",
    path: "",
    meta: index3SAwKaxCNsMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/admin/groups/[slug]/news/index.vue")
  }
]
  },
  {
    name: "donate-slug",
    path: "/donate/:slug()",
    component: () => import("/codebuild/output/src677142924/src/pages/donate/[slug].vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/codebuild/output/src677142924/src/pages/donate/index.vue")
  },
  {
    name: "email-unsubscribe",
    path: "/email-unsubscribe",
    component: () => import("/codebuild/output/src677142924/src/pages/email-unsubscribe.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src677142924/src/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src677142924/src/pages/events/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src677142924/src/pages/forgot-password.vue")
  },
  {
    name: _91slug_93cicg3aGMo3Meta?.name,
    path: "/groups/:slug()",
    component: () => import("/codebuild/output/src677142924/src/pages/groups/[slug].vue"),
    children: [
  {
    name: "groups-slug-files",
    path: "files",
    component: () => import("/codebuild/output/src677142924/src/pages/groups/[slug]/files.vue")
  },
  {
    name: "groups-slug-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src677142924/src/pages/groups/[slug]/gallery.vue")
  },
  {
    name: "groups-slug",
    path: "",
    component: () => import("/codebuild/output/src677142924/src/pages/groups/[slug]/index.vue")
  },
  {
    name: "groups-slug-shop",
    path: "shop",
    component: () => import("/codebuild/output/src677142924/src/pages/groups/[slug]/shop.vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/codebuild/output/src677142924/src/pages/groups/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src677142924/src/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/codebuild/output/src677142924/src/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src677142924/src/pages/login.vue")
  },
  {
    name: "membership-confirmation",
    path: "/membership/confirmation",
    component: () => import("/codebuild/output/src677142924/src/pages/membership/confirmation.vue")
  },
  {
    name: "membership",
    path: "/membership",
    meta: indexXYZyT4yFrtMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/membership/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/codebuild/output/src677142924/src/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/codebuild/output/src677142924/src/pages/news/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/codebuild/output/src677142924/src/pages/reset-password.vue")
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    meta: setup_452fafjFhhfFABGMeta || {},
    component: () => import("/codebuild/output/src677142924/src/pages/setup-2fa.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/codebuild/output/src677142924/src/pages/shop.vue")
  }
]
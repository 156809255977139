<script setup lang="ts">
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

defineProps<{
  error: string | Error | { message: string; stack?: object | any } | any;
}>();

const showStack = ref(false);
</script>

<template>
  <div v-if="error" class="rounded-xl border-2 border-red-300 bg-red-100 p-4 text-left text-base text-gray-900" aria-label="Error message">
    <div class="flex space-x-2">
      <ExclamationTriangleIcon class="size-6 text-red-600" aria-hidden="true" />
      <span>{{ error?.message ? `ERROR: ${error.message}` : `${error || "Unknown error"}` }}</span>
    </div>

    <button v-if="!showStack && error.stack" type="button" class="mt-4 inline-block underline" @click.prevent="showStack = !showStack">
      {{ showStack ? "Hide stack" : "Show stack" }}
    </button>

    <section v-if="showStack && error.stack" class="mt-4 w-full">
      <header class="text-lg font-semibold">Stack</header>
      <div class="max-w-full overflow-auto whitespace-pre-wrap break-all font-mono text-sm" v-html="error.stack"></div>
    </section>
  </div>
</template>

import { getCurrentInstance } from "vue";

/**
 * "Expose" functions on the root HTMLElement
 *
 * NOTE: Must be called within `onMounted()` call.
 *
 * Will use `defineExpose` record if `exposed` argument is not supplied.
 *
 * @param {Object} exposed  (Default `defineExpose` value)
 * @returns {[string]|false}  The list of function names exposed of false of error
 */
export default function exposeMethodsOnCustomElement(exposed?: Record<string, any> | null): string[] | false {
  const instance = getCurrentInstance();
  if (!instance) return false;
  if (!exposed) exposed = instance.exposed;
  if (!exposed) return false;
  const { host } = instance.proxy?.$el?.parentNode || {};
  if (!host) return false;
  const result: string[] = [];
  Object.keys(exposed).forEach((key) => {
    if (typeof exposed![key] === "function" && !Object.prototype.hasOwnProperty.call(host, key)) {
      result.push(key);
      Object.defineProperty(host, key, {
        enumerable: true,
        configurable: true,
        writable: true,
        value: exposed![key],
      });
    }
  });
  return result;
}

<script setup>
import { provideUseId } from "@headlessui/vue";

const { getAbsoluteUrl } = useAbsoluteUrl();
const route = useRoute();

useHead(() => ({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Wildcare` : "Wildcare";
  },
  link: [
    {
      rel: "canonical",
      href: getAbsoluteUrl(route.fullPath),
    },
  ],
}));

provideUseId(() => useId());
</script>

<template>
  <div>
    <NuxtLoadingIndicator color="#20417E" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

export default class DirectusError extends Error {
  constructor(public originalError: Error | DirectusErrorResponse) {
    super();
  }

  get message() {
    return this.first?.message || (this.originalError as Error).message || this.statusText;
  }

  get status() {
    return (this.originalError as DirectusErrorResponse).response?.status;
  }

  get statusText() {
    return (this.originalError as DirectusErrorResponse).response?.statusText;
  }

  get errors() {
    return (this.originalError as DirectusErrorResponse).errors;
  }

  get first() {
    return this.errors?.[0];
  }
}

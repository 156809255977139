import { defineCustomElement } from "vue";
import BulkEmailPreviewSFC from "./BulkEmailPreview.ce.vue";

export const BulkEmailPreview = defineCustomElement(BulkEmailPreviewSFC);

export function defineCustomElements() {
  customElements.define("custom-bulk-email-preview", BulkEmailPreview);
}

// --- Register global typings
// declare module 'vue' {
//   export interface GlobalComponents {
//     'Counter': typeof Counter,
//   }
// }

import type { AsyncData, NuxtError } from "#app";

type RefreshOpts = {
  /**
   * Determine if refreshing should wait or cancel ongoing refreshing
   */
  dedupe?: "cancel" | "defer";
};

/**
 * Return user/profile data and helpers to refresh them
 */
export default function useUser() {
  userWarmup();

  const userFetcher = getFetcher();

  const getTheUser = () => userFetcher.data.value || null;

  const getTheProfile = () => userFetcher.data.value?.user_profiles?.[0] || null;

  return computed(() => {
    // --- Read the user data so this gets re-computed when it changes.
    //     This will allow watchers on the computed itself react when user refreshes.
    getTheUser();

    return {
      get data() {
        return getTheUser();
      },

      get user() {
        return getTheUser();
      },

      get profile() {
        return getTheProfile();
      },

      // --- Proxy the fetcher helpers
      dataRef: userFetcher.data,
      pending: userFetcher.pending,
      refresh: (opts?: RefreshOpts) => {
        // --- Do not refresh in case we are already refreshing (or opts say otherwise)
        if (!opts && userFetcher.pending.value) return getPendingPromise();
        return userFetcher.refresh(opts);
      },
      clear: userFetcher.clear,
      error: userFetcher.error,
      status: userFetcher.status,
      get pendingPromise() {
        return getPendingPromise();
      },
    };
  });
}

/**
 * Fetch user data only once
 */
export function userWarmup() {
  const userFetcher = getFetcher();
  const isSSR = import.meta.server;
  if (!isSSR && userFetcher.status.value === "idle" && !userFetcher.data.value) {
    // --- Kick off user/profile data warmup
    userFetcher.execute();
  }
}

/**
 * Generate a promise that waits on the pending value to change
 */
export function getPendingPromise() {
  const userFetcher = getFetcher();
  if (!userFetcher.pending.value) return Promise.resolve();
  return new Promise<void>((resolve) => {
    const _off = watch(userFetcher.pending, () => {
      _off();
      resolve();
    });
  });
}

function fetchUser() {
  return useDirectus(
    readMe({
      fields: [
        "*",
        {
          branch_members: ["*"],
          event_participants: ["*"],
          user_profiles: ["*"],
          youths: ["*"],
          peers: ["*"],
          role: ["*"],
        },
      ],
    })
  );
}

export type DirectusUserMix = Awaited<ReturnType<typeof fetchUser>>;

let _fetcher: AsyncData<DirectusUserMix | null, NuxtError<unknown> | null> | null = null;

function getFetcher() {
  if (!_fetcher) {
    _fetcher = useAsyncData(
      // eslint-disable-next-line require-await
      async () => {
        const isSSR = import.meta.server;
        if (isSSR) throw new Error("Cannot get current user on the server");
        return fetchUser();
      },
      { immediate: false, server: false }
    );
    return _fetcher;
  }
  return _fetcher;
}

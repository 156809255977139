import { createDirectus, rest, authentication } from "@directus/sdk";

import type { CustomDirectusTypes } from "~/types/directus-collections.d.ts";
import DirectusError from "~/utils/DirectusError";

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  const directus = createDirectus<CustomDirectusTypes>(config.public.directusUrl)
    .with(authentication("cookie", { credentials: "include" }))
    .with(rest());

  try {
    await directus.refresh();
  } catch (e) {
    const err = new DirectusError(e as Error);
    if (err.first?.extensions?.code !== "INVALID_PAYLOAD") {
      console.warn("could not refresh token");
      console.error(e);
    }
  }

  return {
    provide: {
      directus,
    },
  };
});

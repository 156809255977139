import type { RestCommand } from "@directus/sdk";
import type { CustomDirectusTypes } from "~/types/directus-collections";
import DirectusError from "~/utils/DirectusError";

export default async function <Schema extends object>(options: RestCommand<Schema, CustomDirectusTypes>) {
  const nuxtApp = useNuxtApp();
  const directus = nuxtApp.$directus;
  try {
    return await directus.request<Schema>(options);
  } catch (err) {
    // console.log("---directus error:", err);
    throw new DirectusError(err as Error | DirectusErrorResponse);
  }
}

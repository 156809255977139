export default async function useGetUser() {
  const user = useUser();

  await user.value.refresh();

  const { data: me } = user.value;
  if (me && (!me.user_profiles || me.user_profiles.length > 1)) {
    console.error("User profiles array is greater than 1 or doesn't exist:", me.id, me.first_name);
  }

  return user;
}

// This is a temporary redirect to the Tasmanian Nature Conservation Fund page as the ONLY cause we are supporting at the moment
export default defineNuxtRouteMiddleware(async (to, _from) => {
  if (to.fullPath === "/donate") {
    const causes = await useDirectus(
      readItems("causes", {
        fields: ["*"],
        filter: {
          status: { _eq: "published" },
        },
      })
    );
    if (causes.length === 1) {
      return navigateTo(`/donate/${causes[0].slug}`);
    }
  }
});

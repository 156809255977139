export const useAbsoluteUrl = () => {
  const url = useRequestURL();
  const getAbsoluteUrl = (path: any) => {
    const baseUrl = url.protocol + "//" + url.host;
    if (path.endsWith("/")) {
      return baseUrl + path.slice(0, -1);
    } else {
      return baseUrl + path;
    }
  };
  return { getAbsoluteUrl };
};

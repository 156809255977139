<script setup lang="ts">
import DirectusError from "~/utils/DirectusError";

/**
 * WARNING
 *
 * You _cannot_ use TailwindCSS classes here, nor any other components that
 * use them, since no style is compiled by tailwind for custom-elements!
 *
 * You _cannot_ use Nuxt app or config, since Nuxt doesn't process
 * custom-elements!
 */

const props = defineProps<{
  bulkEmailId: string;
}>();

const id = computed(() => props.bulkEmailId);

// --- I didn't want the authToken as a prop - visible in the DOM, so it is set
//     using the `setAuthToken` function, which is exposed on the root node.
const authToken = ref<string>("");

const html = ref("");

// --- We cannot use Nuxt within this custom-element, so we'll rely on Vite
const baseUrl = DIRECTUS_BASE_URL as string;

const fetchHTML = async () => {
  const _id = id.value;
  if (!_id) {
    console.warn("No bulk_emails id to preview");
    html.value = "";
    return;
  }
  try {
    const url = `${baseUrl}/preview-bulk-emails/${_id}`;
    const result = await fetch(url, {
      headers: authToken.value ? { Authorization: `Bearer ${authToken.value}` } : undefined,
      credentials: "include",
    });
    const text = await result.text();
    if (!result.ok) throw JSON.parse(text);
    html.value = text;
  } catch (err) {
    const _error = new DirectusError(err as Error);
    console.error(`Error fetching bulk_emails preview for id "${_id}": ${_error?.message || _error}`);
  }
};

watch(
  [id, authToken],
  (current, old) => {
    const [id, authToken] = current;
    const [oldId] = old;
    if (id && authToken) fetchHTML();
    else if (!id && oldId) html.value = "";
  },
  { immediate: true }
);

defineExpose({
  setAuthToken(value: string) {
    authToken.value = value;
  },
});

onMounted(() => {
  exposeMethodsOnCustomElement();
});
</script>

<template>
  <div v-if="html" v-html="html"></div>
</template>
